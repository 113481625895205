/* orange-button.module.css */
.button {
    background-color: #03989F;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.3s;
}

.button:hover {
    background-color: #00B2BB;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}