.card {
    border-radius: 8px;
    overflow: hidden;
    max-width: 300px;
    margin: 0 auto;
    height: auto;
    max-height: 435px;
    width: 100%;
    display: flex;
    flex-direction: column;
    transition: 0.3s;
    text-decoration: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .card:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    text-decoration: none;
  }
  
  .image {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  
  .details {
    padding: 16px;
  }
  
  .title {
    color: black;
    font-size: 1.5em;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .price {
    color: #555;
    font-size: 1.2em;
    margin: 8px 0;
  }
  
  .location, .date {
    color: #555;
    font-size: 0.9em;
    margin: 4px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .button {
    background: linear-gradient(45deg, #03989F, #00B2BB);
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1em;
    width: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background 0.3s ease;
  }
  
  .button:hover {
    background: linear-gradient(45deg, #00B2BB, #03989F);
  }
  
  .menu {
    position: absolute;
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    top: 100%;
    z-index: 1000;
    width: 150px;
  }
  
  .menu ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu li {
    padding: 10px;
    cursor: pointer;
  }
  
  .menu li:hover {
    background: #f0f0f0;
  }
  
  .menuItem {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
  }
  
  @media (max-width: 768px) {
    .card {
      width: 200px;
      max-width: 100%;
      height: auto;
      max-height: 360px;
    }
  
    .image {
      height: 200px;
    }
  
    .title {
      font-size: 1.2em;
    }
  
    .price {
      font-size: 1em;
    }
  
    .location, .date {
      font-size: 0.8em;
    }
  
    .details {
      padding: 12px;
    }
  
    .button {
      padding: 8px 16px;
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .card {
      max-width: 100%;
      height: auto;
      max-height: 310px;
    }
  
    .title {
      font-size: 1em;
    }
  
    .image {
      height: 150px;
    }
  
    .price {
      font-size: 0.9em;
    }
  
    .location, .date {
      font-size: 0.7em;
    }
  
    .details {
      padding: 8px;
    }
  
    .button {
      padding: 6px 6px;
      font-size: 0.8em;
    }
  }