.submitButton {
    font-size: 20px;
    width: 100%;
    height: 40px;
    background-color: #FFBF34;
    color: white;
    border: none;
    margin-bottom: 50px;
    border-radius: 10px;
}

.submitButton:hover {
    background-color: #FFCB5B;
}

.customLink {
    color: #03989F;
}

.customLink:hover {
    color: #00B2BB;
}