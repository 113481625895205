.userInfo {
  position: relative;
  display: flex;
  justify-content: center;
}

.photoContainer {
  position: relative;
  display: inline-block;
}

.userPhoto {
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  margin: 0 auto;
  margin-bottom: 20px;
}

.spinOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.7); /* Полупрозрачный фон */
  border-radius: 50%;
}

.uploadIcon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deleteBanner {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 20px;
  color: red;
  cursor: pointer;
}

.submitButton {
    width: 100px;
    height: 40px;
    background-color: #FFBF34;
    color: white;
    border: none;
    border-radius: 5px;
    margin-right: 16px;
    font-size: 16px;
    transition: 0.3s;
}

.submitButton:hover {
    background-color: #FFCB5B;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}