.container {
  max-width: 1170px;
  margin: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

ul {
  list-style: none;
}

.footer {
  background-color: #ffff;
  padding: 10px 0;
  margin-top: 20px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
  border-top: 1px solid #e0e0e0;
}

.footer * {
  padding: 0;
  box-sizing: border-box;
}

.footerCol {
  width: 50%;
  padding: 0 15px;
}

.footerCol h4 {
  font-size: 18px;
  color: #000000;
  text-transform: capitalize;
  margin-bottom: 20px;
  font-weight: 500;
  position: relative;
}

.footerCol h4::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #2bdad7;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footerCol ul li:not(:last-child) {
  margin-bottom: 10px;
}

.footerCol ul li a {
  font-size: 16px;
  text-transform: capitalize;
  color: #000000;
  text-decoration: none;
  font-weight: 300;
  display: block;
  transition: all 0.3s ease;
}

.footerCol ul li a:hover {
  color: #d7d7d7;
  padding-left: 8px;
}

.footerCol .socialLinks a {
  display: inline-block;
  height: 40px;
  width: 40px;
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #000000;
}

/*responsive*/
@media(max-width: 767px) {
  .footerCol {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media(max-width: 574px) {
  .footerCol {
    width: 50%;
  }
}

.downloadApps {
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .downloadApps {
    padding-bottom: 60px;
  }
}
