.dropdownContainer {
  position: relative;
  display: inline-block;
}

.dropdownButton {
  width: 150px;
  height: 40px;
  background-color: #ffbf34;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 16px;
  font-size: 16px;
  transition: 0.3s;
}

.dropdownButton:hover {
  background-color: #ffcb5b;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
}

.dropdownMenu {
  display: block;
  position: absolute;
  background-color: white;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 2;
  animation: fadeIn 0.3s;
  border-radius: 5px;
}

.dropdownItem {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  transition: 0.3s;
}

.dropdownItem:hover {
  background-color: #ddd;
  border-radius: 5px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

