@media (max-width: 1000px) {
    .body {
        padding-bottom: 4.5rem;
    }
}
@media (min-width: 1000px) {
  .body {
        padding-top: 4.5rem;
        padding-bottom: 2.5rem;
    }
}

.submitButton {
    font-size: 20px;
    width: 100%;
    height: 40px;
    background-color: #FFBF34;
    color: white;
    border: none;
    border-radius: 10px;
}

.submitButton:hover {
    background-color: #FFCB5B;
}

.customLink {
    text-decoration: none;
    color: #03989F;
}

.customLink:hover {
    color: #00B2BB;
}