@media (max-width: 1000px) {
    .bodyStyle {
        padding-top: 3.5rem;
        padding-bottom: 4.5rem;
    }
}

@media (min-width: 1000px) {
    .bodyStyle {
        padding-bottom: 3.5rem;
        padding-top: 4.5rem;
    }
}


.messageHistory {
    margin-bottom: 70px;
}

.userPhoto {
    border-radius: 50%;
    object-fit: cover;
    width: 50px;
    height: 50px;
    margin-left: 10px;
}

.messageFromBlock {
    background-color: #03989F;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    color: #ffffff;

}

.outgoingMessage {
    display: flex;
    justify-content: flex-end;
}

.incomingMessage {
    display: flex;
    justify-content: flex-start;

}

.messageToBlock {
    background-color: orange;
    color: white;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 10px;
    max-width: 70%;
    display: flex;
    flex-direction: column;
}

.messageText {
    background-color: orange;
    color: white;
}

.timeStamp {
    font-size: 10px;
}

.messageText {
    display: flex;
    align-items: center;
}

.inputMessage {
    display: flex;
    align-items: center;
    border-top: solid 1px #c4c4c4;
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 999;
    background-color: white;
    height: 70px;
}

.textInput {
    margin-left: 5px;
    border-radius: 20px;
}

.sendIcon {
    font-size: 20px;
    margin-left: 10px;
    margin-right: 5px;
    color: #FFBF34
}

.sendButton {
    font-size: 20px;
    border: none;
}